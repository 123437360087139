.hero {
  min-height: 80vh;
  padding: 50px 0px;
  background-image: url(../../assets/images/bg-hero.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero .links-social {
  display: flex;
  gap: 20px;
}
.hero .links-social a {
  color: var(--text-light);
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
}
.hero .form-modal {
  background-color: var(--text-light);
  min-height: 200px;
  width: 90%;
  margin: auto;
  margin-top: 70px;
  padding: 20px;
}
.hero .form-modal > h2 {
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  padding: 20px 0px;
}
.hero form > div {
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
  margin-top: 15px;
}
.hero form > div > div {
  width: 100%;
}
.hero form label {
  font-size: 15px;
  font-weight: 500;
}
.hero form input {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.504);
  height: 50px;
  border-radius: 5px;
  padding-left: 10px;
  display: block;
}
.hero .input-group span img {
  display: block;
  width: 23px;
}
.store-logo {
  width: 100px;
  height: 100px;
  color: #fff;
  margin: 5px;
}
.hero .input-group input {
  width: calc(100% - 60px);
}
.hero form select {
  width: 100%;
  height: 45px;
  border: 1px solid rgba(128, 128, 128, 0.504);
  border-radius: 5px;
  padding-left: 15px;
}
.hero form button {
  width: 100%;
  height: 45px;
  background-color: var(--button-blue);
  border: var(--button-blue);
  margin-top: 30px;
}
.content-hero {
  padding-top: 100px;
}
.hero .content-hero h1 {
  font-size: 73px;
  color: var(--text-light);
}
.hero .content-hero > p {
  font-size: 20px;
  color: var(--gray);
  opacity: 0.9;
  margin-top: 15px;
}
.hero .content-hero .list {
  margin-top: 15px;
}
.hero .content-hero .list div {
  display: flex;
  align-items: center;
  color: var(--gray);
  opacity: 0.9;
}
.hero .content-hero .list div p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 17px;
}
.hero .error {
  color: red;
  font-size: 12px;
  /* margin-top: -5px; */
  display: block;
}
.go_toScanne {
  background: var(--button-blue);
  color: #fff;
  border: 0px;
  padding: 8px 20px;
  border-radius: 5px;
  margin: auto;
  display: block;
  margin-top: 20px;
  display: none;
}
@media screen and (max-width: 763px) {
  .content-hero {
    padding-top: 0px;
  }
  .go_toScanne{
    display: block;
  }
}
