.about-us .header {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.about-us .title_header{
  margin: 0px;
}
.about-us .section h2 {
  color: rgb(6 144 168);
  font-size: 18px;
  font-weight: 500;
}
.about-us .section {
  padding: 12px 0px;
}
.about-us section p {
  font-size: 15px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
}
.about-us .section .member-info > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.about-us .section .member-info > div p {
  margin: 0px;
  font-size: 13px;
  color: rgb(3, 94, 61);
}
.about-us .section h3 {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}
.about-us .about_us_content {
  position: relative;
  margin: 33px 0px;
}
