.booking .slide-servide img{
    border-radius: 8px;
}
.booking h3 {
    font-size: 24px !important;
}
.booking-detail{
    padding: 20px 0px;
}
.booking-detail p{
    font-size: 16px;
    color: gray;
    font-weight: 500;
}
.booking-detail .prix-and-date{
    display: flex;
    gap: 20px;
}
.booking-detail .prix-and-date .prix{
    width: 65%;
}
.booking-detail .prix-and-date .prix > div{
    border-radius: 5px;
    box-shadow:  0 6px 24px 9px rgba(26, 27, 31, 0.08);
    padding: 20px 30px;
    margin-top: 25px;
}
.booking-detail .prix-and-date .prix p{
    margin-bottom: 0px;
}
.booking-detail .prix-and-date .prix h4{
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
}
.booking-detail .prix-and-date .prix span{
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: gray;
}
.booking-detail .service-card{
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.188);
}
.booking-detail .service-card:last-of-type{
    border-bottom: 0px;
}
.booking-detail .prix-and-date .date{
    width: 30%;
}
.booking-detail .prix-and-date .date-detail{
     border-radius: 5px;
    box-shadow:  0 6px 24px 9px rgba(26, 27, 31, 0.08);
    padding: 20px 30px;
    margin-top: 25px;
}
.booking-detail .prix-and-date .date .card-date{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.188);
    padding: 15px 0px;

}
.booking-detail .prix-and-date .date .card-date:last-of-type{
    border-bottom: 0px;
}
.booking-detail .prix-and-date .date .card-date p{
    font-size: 16px;
    color: gray;
    font-weight: 400;
}
.booking-detail .prix-and-date .date .card-date span{
    font-size: 16px;
    color: #000;
}
.booking-detail .prix-and-date .date .card-date .fermer{
    font-weight: 500;
    color: rgb(108, 108, 108);
}
@media screen and (max-width:992px) {
    .booking-detail .prix-and-date{
        flex-direction: column;
    }    
    .booking-detail .prix-and-date > div{
        width: 100% !important;
    }    
}