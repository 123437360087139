.noubty-scaner {
  position: relative;
  min-height: 100vh;
}
.noubty-scaner h1 {
  color: #015d6b6b;
  text-align: center;
  font-size: 18px;
  padding: 20px 0px;
}
.content-scan {
  position: relative;
  z-index: 111;
}

.noubty-scaner span#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}
.header {
  width: 100%;
  height: 30vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  color: #fff;
  position: relative;
}
.header > img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 60vh;
  object-fit: cover;
}
.header .title_header {
  margin: auto;
  margin-top: 0;
  position: relative;
}
.header div img {
  width: 40px;
  margin: 0 auto;
  position: relative;
}
.header h2 {
  font-size: 25px;
  font-weight: 500;
  position: relative;
}

/* .noubty-scaner div:last-of-type{
  max-width: 450px;
} */
.header .icons {
  display: flex;
  justify-content: space-between;
  position: relative;
  color: #fff;
  z-index: 111;
  margin-bottom: 20px;
}
.header .icons svg {
  width: 50px;
  height: 50px;
}
/* @media screen and (max-width: 1080px) {
  .header > img {
    top: -132% !important;
  }
}

@media screen and (max-width: 990px) {
  .header > img {
    top: -107% !important;
  }
}
@media screen and (max-width: 800px) {
  .header > img {
    top: -72% !important;
  }
}
@media screen and (max-width: 650px) {
  .header > img {
    top: -61% !important;
  }
}
@media screen and (max-width: 625px) {
  .header > img {
    top: -52% !important;
  }
}
@media screen and (max-width: 600px) {
  .header > img {
    top: -0% !important;
  }
} */
.footer_wave {
  position: relative;
  width: 100%;
  height: 30vh;
}
.footer_wave > img {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30vh;
}
.content-scan {
  height: 40vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-scan > div {
}

.qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.qr-code-svg {
  width: 90%;
  height: auto;
}
.big-icon-scan {
  text-align: center;
}
.big-icon-scan svg {
  width: 200px;
  height: 200px;
  color: #015d6b6b;
}
.container-btn-scanne {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* height: 90%; */
  /* margin: 120px 0px; */
}
.container-btn-scanne p {
  margin: 0;
}
.upload-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.upload-button input {
  display: none;
}
.camera-controls {
  text-align: center;
}
.camera-controls video {
  width: 80% !important;
  border-radius: 20px;
}

.camera-controls button {
  display: block;
  margin: auto;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.camera-controls button:hover {
  background-color: #0056b3;
}

.dropdown-container {
  bottom: 10px !important;
  right: 10px !important;
}
