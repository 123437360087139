.card {
  text-align: center;
  position: relative;
  border: 0px !important;
}

.card .details_doctor {
  display: flex;
  gap: 10px;
}
.card .details_doctor img {
  cursor: pointer;
}

.doctor-info {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: start;
}

.doctor-img {
  max-width: 400px;
  width: 50%;
  height: 130px;
  border-radius: 10px;
}
.doctor-info p {
  color: #015c6b8f;
  font-size: 16px;
}

.card h3 {
  color: #015c6b;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.queue-info {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 10px;
}
.queue-info p {
  color: #015c6b8f;
  font-size: 20px;
  font-weight: 700;
}

.queue-info h4 {
  font-size: 25px;
  margin: 5px 0;
  color: #015c6b;
  font-weight: 800;
}

.queue-numbers {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

.content-card {
  /* margin: 55px 0px 75px 0px; */
  position: relative;
  z-index: 222;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.content-card > div {
  width: 100%;
}
@media screen and (min-width: 514px) {
  .content-card {
    /* margin: 102px 0px 75px 0px; */
  }
}
.queue-numbers button {
  color: #11acc6;
  border: 2px solid #11acc6;
  background-color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 8px 30px;
  border-radius: 30px;
  width: 50%;
}
.queue-numbers button:first-of-type {
  margin-right: -12px;
}
.queue-numbers button:last-of-type {
  margin-left: -12px;
}
.content-footer {
  position: absolute;
  top: 20%;
  width: 100%;
}
.footer_wave p {
  padding: 10px 20px;
  color: #015d6b6a;
  border: none;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}

.quit-button {
  background-color: #015d6b6a;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 70%;
}
.quit-button svg {
  width: 20px;
  height: 20px;
}

.quit-button:hover {
  background-color: #008fcc;
}

.dropdown-container {
  position: absolute;
  right: 82%;
  bottom: 0px;
  transform: translate(-50%, -18%);
}

.more-icon {
  cursor: pointer;
  background-color: yellow;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.dropdown_menu {
  position: absolute;
  right: 46px;
  width: 200px;
  bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background-color: #fff;
}

.dropdown_menu a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: black;
}

.dropdown_menu a:hover {
  background-color: #f0f0f0;
  border: 0.2px solid #015d6b67;
}
