.faq h2{
    font-size: 2.5rem;
    text-align: center;
    font-weight: 500;
}
.faq .accordion-item {
  border: 0px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.236);
  border-radius: 0px !important;
  padding: 25px 0px;
}
.faq .accordion-button {
  background-color: transparent !important;
  font-size: 21px;
  box-shadow: none !important;
}
.faq .accordion-button :focus {
  box-shadow: none;
}
.faq .accordion-button ::after {
  background-size: 15px;
}
.faq .accordion-item:last-of-type {
  border-bottom: 0px;
}
