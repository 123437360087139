.advantages h2{
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 35px;
}
.advantages h3{
    font-size: 2.5rem;
}
.advantages img{
    border-radius: 10px;
}
.advantages{
    padding-top: 40px;
    padding-bottom: 40px;
}
.advantages .row{
    align-items: center;
}
.advantages p{
    font-size: 1.25rem;
    margin-bottom: 0;
}
.advantages .list{
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
}
.advantages p svg{
    margin-right: 10px;
}
.advantages button{
    font-size: 1rem;
    padding: 10px 15px;
    background-color: var(--button-blue);
    color: var(--text-light);
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid var(--button-blue);
    width: fit-content;
}

@media screen and (max-width:1226px) {
    .advantages img{
        width: 100%;
    }    
}