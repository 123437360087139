.header_top {
  background-color: #fff;
  min-height: 100px;
}
.header_top .navbar-nav {
  height: auto;
  overflow: hidden;
}
.header_top .nav-link {
  font-size: 18px;
  font-weight: 400;
  color: var(--black);
  position: relative;
  text-align: center;
  width: fit-content;
  margin: auto;
}
.navbar {
  padding: 10px !important;
}
.header_top .links-principal a::after {
  content: "";
  width: 0;
  height: 0.5px;
  background-color: var(--black);
  position: absolute;
  top: 100%;
  left: 0;
  transition: width 0.3s;
}

.header_top .links-principal a:hover::after {
  width: 100%;
}
.header_top .connect {
  width: 135px;
  height: 45px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
}
.header_top .connect:hover {
  background-color: rgba(128, 128, 128, 0.301);
}
.header_top .btn-header {
  border: 2px solid var(--button-blue);
  background-color: transparent;
  color: var(--button-blue);
  font-size: 17px;
  font-weight: 500;
  margin: auto;
  display: block;
}
.header_top .btn-header:hover {
  border: 2px solid var(--button-blue);
  background-color: var(--button-blue);
  color: var(--text-light);
  font-size: 17px;
  font-weight: 500;
}
.navbar-brand img {
  width: 60px;
  height: 60px;
  display: none;
}
@media screen and (max-width: 763px) {
  .navbar-brand img {
    display: block;
  }
  .header_top .btn-header {
    display: none;
  }
}

.custom-modal-body {
  padding: 40px 35px;
  background-color: #f8f9fa;
  border-radius: 15px;
  font-size: 1.2rem;
  color: #333;
}

.custom-close-button {
  background-color: #007bff;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.custom-close-button:hover {
  background-color: #0056b3;
}

.custom-close-button:focus {
  box-shadow: none;
}
