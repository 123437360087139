.footer {
  background-color: var(--black);
  padding: 50px 0 20px 0px;
  margin-top: 20px;
  border-top: 1px solid #e7e7e7;    
}

.footer-column {
  /* text-align: center; */
  margin-bottom: 20px;
}

.footer-logo {
  max-width: 100px;
  margin-bottom: 15px;
}

.footer-column h5 {
  margin-bottom: 15px;
  color: var(--text-light);
  font-size: 1.5rem;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}
.footer .icons-social {
  display: flex;
  gap: 15px;
  color: #fff;
}
.footer .icons-social svg {
  width: 35px;
  height: 35px;
}

.footer-column ul li {
  padding: 7px 0px;
  margin-left: 10px;
  width: fit-content;
  border: 1px solid transparent;
  transition: 0.4s;
}
.footer .first-column {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.footer .first-column div {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  text-align: center;
}
.footer .first-column div a {
  text-decoration: none;
  font-size: 17px;
  color: var(--text-light);
  font-weight: 500;
}
.footer-column ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 17px;
}

.footer-column ul li:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer-column p {
  margin: 0;
}
.footer .copyright {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5px;
  transition: 0.5s;
  /* padding */
}
.footer .copyright a {
  display: block;
  color: #e9e9e9;
  text-decoration: none;
}
.footer .copyright a:hover {
  color: var(--button-yellow);
}
.footer-column ul li {
  margin-left: 0;
  padding-left: 0;
}
@media screen and (max-width: 950px) {
  .copyright {
    font-size: 12px !important;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 763px) {
  .footer {
    text-align: center;
  }
  .footer .first-column {
    align-items: center;
  }
  .footer .first-column div a {
    font-size: 15px;
  }
  .footer-column ul li a {
    display: block;
    text-align: center;
  }
  .footer-column ul li {
    width: auto;
  }
}
