.background-home {
  position: relative;
  min-height: 100vh;
}

.schedule-container {
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.schedule-item {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.schedule-item h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.schedule-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.status {
  font-weight: bold;
  color: green;
}

.line-through {
  text-decoration: line-through;
  color: #888;
}

.not-working .status {
  color: red;
}
.background-home > img:first-of-type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}
.background-home > img:last-of-type {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 0;
  transform: translateY(-100%);
}
