.growth{
    padding: 80px 0px;
}
.growth .container{
    display: flex;
    flex-wrap: nowrap;
}
.growth .container > div{
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.608);
    padding: 32px;
    cursor: pointer;
    background-color: #fff;
    transition: .3s;
}
.growth .container > div:hover{
    transform: scale(1.02);
    box-shadow: 1px 1px 17px -5px gray;
}
.growth .container .growth_title{
    font-size: 2.75rem;
}
.growth .container .growth_body{
    font-size: 1.25rem;
}
@media screen and (max-width:1000px) {
    .growth .container{
        flex-direction: column;
        text-align: center;
    }    
}