.service{
    max-width: 1500px;
    margin: auto;
    margin-top: -50px;
    position: relative;
    z-index: 22;
    background-color: var(--text-light);
    border-radius: 40px;
    padding: 60px 20px;
}
.service .description{
    font-size: 20px;
}
.service h2{
    font-size: 32px;
    font-weight: 500;
    padding: 50px 0px;
}
.service h3{
    font-size: 24px;
    font-weight: 500;
    padding: 20px 0px;
}
.service article p{
    font-size: 19px;
}
.service article section > img{
    width: 100%;
    padding: 20px 0px;
}
.service .bottom-links h3{
    padding: 10px 0px;
}
.service .bottom-links ul{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.service .bottom-links ul li{
    width: 50%;
    list-style: none;
     font-size: 18px;
    font-weight: 600;
    text-decoration: underline !important;
    display: block;
    padding: 10px 0px;
}
.service .bottom-links ul li a{
    color: gray;
}
.list_container .footer , .list_container .header_top{
    display: none;
}
