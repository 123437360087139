.salon-list {
  padding: 20px;
}
.salon-list a{
  text-decoration: none;
  color: #000;
}

.salon-list h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.salon-list p {
  margin-bottom: 20px;
}

.salons {
  display: flex;
  flex-direction: column;
}

.salon-card {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 20px;
}

.salon-image {
  width: 300px;
  height: 208px;
  object-fit: cover;
  border-radius: 10px;
}

.salon-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.salon-details h3 {
  margin: 0;
  font-size: 20px;
}

.salon-details p {
  margin: 5px 0;
  color: gray;
  font-weight: 500;
}
.salon-details p:first-of-type{
  text-decoration: underline;
}

.salon-details button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
  margin-top: 20px;
}

.salon-details button:hover {
  background-color: #444;
}

@media screen and (max-width:763px) {
      .salon-card{
        flex-direction: column;
        padding: 0px;
      }  
      .salon-card img{
        width: 100%;
        border-radius: 8px 8px 0px 0px;
      }
      .salon-card > div{
        margin: auto;
      }
      .salon-card button{
        width: 100%;
      }
}