.background-home {
  padding: 20px;
  background-color: #f4f4f9;
}

.scroll-view {
  padding-top: 15%;
  position: relative;
  z-index: 1;
}

.item-container {
  margin: 10px 15px;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-text {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
}

.normal-text {
  font-size: 14px;
  color: #000;
  margin-bottom: 7px;
}

.loader {
  margin: 20px;
}

.marker {
  background-color: red;
  padding: 5px;
  color: white;
  border-radius: 50%;
  text-align: center;
}
